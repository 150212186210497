import { Controller } from 'react-hook-form';
import { Box, TextField as MuiTextField, Typography } from '@mui/material';
import { useMemo } from 'react';

export const ControlledTextField = (props: any) => {
  const {
    control,
    disabled,
    name,
    label,
    labelColor = '#666666',
    error,
    placeholder,
    required,
    defaultValue,
    textFieldProps,
    type = 'text',
    dark = false,
    width,
    maxLength,
  } = props;

  const styles = useMemo(
    () =>
      dark
        ? {
            inputProps: {
              style: {
                WebkitTextFillColor: 'white',
                WebkitBoxShadow: 'none',
                fontWeight: '400 !important',
              },
            },
            sx: {
              input: {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderWidth: '2px !important',
                  borderColor: '#313431 !important',
                },
                '&.Mui-focused fieldset': {
                  borderWidth: '2px !important',
                  borderColor: '#313431 !important',
                },
                '&.Mui-disabled fieldset': {
                  borderColor: '#313431 !important',
                },
              },
            },
          }
        : {},
    [dark],
  );

  const disableScroll = (e: any) => {
    e.target.addEventListener(
      'wheel',
      (e: any) => {
        e.preventDefault();
      },
      { passive: false },
    );
  };

  const preventE = (e: any) => {
    if (e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };

  return (
    <Box
      width={width ? width : '100%'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {label && (
        <Box display="flex" columnGap={0.5} marginY={0.5}>
          <Typography
            fontWeight="500"
            fontSize="16px"
            lineHeight="15px"
            color={labelColor}
            marginTop="10px"
          >
            {label}
          </Typography>
          {required && (
            <Typography fontSize="15px" color="#ff4242">
              *
            </Typography>
          )}
        </Box>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MuiTextField
            placeholder={placeholder}
            type={type}
            variant="outlined"
            fullWidth
            margin="dense"
            inputProps={{ maxLength }}
            disabled={disabled}
            error={!!error[name]}
            helperText={error[name]?.message ?? ''}
            {...styles}
            {...field}
            {...textFieldProps}
            onFocus={disableScroll}
            onKeyDown={type === 'number' ? preventE : undefined}
          />
        )}
      />
    </Box>
  );
};
