import { KeyboardArrowLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { CustomModal } from 'components/Modals/CustomModal';
import { MachineForm } from 'components/Forms/MachineForm';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { BaseTable } from 'components/Tables/BaseTable';
import { columns } from './machines';
import { Layout } from 'components/Layout';
import { useNotification } from 'shared/hooks/useNotification';
import ContractsService from 'shared/services/contracts.service';
import MachinesService from 'shared/services/machines.service';
import {
  fieldsStyle,
  fieldsStyleGray,
  titleSize,
  titleFontSize,
  titleLabelGap,
  emailMaxWidth,
  contactDetailsBox,
  outerContainer,
  innerContainer,
  contactDetailsInnerBox,
  borderStyles,
} from './styles';
import { RedesignedTable } from 'components/Tables/RedesignedTable';
import { colors } from 'shared/constants/theme';
import { InvoicesTable } from 'components/Tables/InvoicesTable';

const RenderField = (label: string, value?: string | number): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: titleLabelGap,
      }}
    >
      <Box
        sx={{
          width: titleSize,
          color: 'secondary.main',
          fontSize: titleFontSize,
        }}
      >
        {label}
      </Box>
      <Box
        sx={{
          fontSize: titleFontSize,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: emailMaxWidth,
        }}
      >
        {value}
      </Box>
    </Box>
  );
};

const ContractData = (props: any): JSX.Element => {
  const { isCustomer } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  const [contract, setContract] = useState<Record<string, any>>({});
  const [machines, setMachines] = useState<Record<string, any> | null>(null);
  const [delMachine, setDelMachine] = useState<boolean>(false);
  const [machineData, setMachineData] = useState<Record<string, any>>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [isMachineAdded, setMachineAdded] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { showSnackbar } = useNotification();

  const fetchContract = async () => {
    try {
      const contract = await ContractsService.getContract(id!);
      setContract(contract);
    } catch (err) {}
  };

  useEffect(() => {
    fetchContract();
  }, [id]);

  const fetchMachines = async () => {
    setMachines(null);
    try {
      const data = await MachinesService.getMachinesByContract(id!, {
        pageNumber: currentPage,
        limit: rowsPerPage,
      });
      const { machines, totalPages } = data;
      setMachines(machines);
      setTotalPages(totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchMachines();
  }, [id, rowsPerPage, currentPage]);

  const handleDeleteMachine = async (machineId: string) => {
    setDelMachine(true);
    setMachineData({ machineId });
  };

  const removeMachine = async () => {
    await MachinesService.deleteMachine(machineData.machineId);
    fetchMachines();
    setDelMachine(false);
    showSnackbar('Successfully Deleted', 'success');
  };

  const handleEdit = (machine: any) => {
    setShowEditModal(true);
    setMachineData(machine);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  return (
    <Layout>
      {!isCustomer ? (
        <CustomModal
          open={isMachineAdded}
          onClose={() => setMachineAdded(false)}
        >
          <MachineForm
            setShowModal={setMachineAdded}
            onFinish={fetchMachines}
          />
        </CustomModal>
      ) : null}

      <Box sx={outerContainer}>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            alignSelf: 'start',
          }}
        >
          <Button
            sx={{
              borderColor: colors.lightGrey,
              borderRadius: 2,
              padding: '5px',
              minWidth: 'unset',
            }}
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            <KeyboardArrowLeft />
          </Button>
          <SectionHeading
            sx={{
              color: 'white',
            }}
            name={contract.name}
          />
        </Box>
        <Box sx={innerContainer}>
          <Box sx={contactDetailsBox}>
            <Box sx={contactDetailsInnerBox}>
              <Box sx={{ ...fieldsStyle }}>
                {RenderField('Contract details')}
              </Box>
              <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
                {RenderField('Customer Email', contract?.user?.email)}
              </Box>
              <Box sx={fieldsStyle}>
                {RenderField('Hearst Up Front', contract?.hearstUpfront)}
              </Box>
              <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
                {RenderField('Electricity Cost', contract?.electricityCost)}
              </Box>
              <Box sx={fieldsStyle}>
                {RenderField('Hosting Company', contract?.hostingCompany)}
              </Box>
              <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
                {RenderField('Hosting Cost', contract?.hostingCost)}
              </Box>
              <Box sx={fieldsStyle}>
                {RenderField('Location', contract?.location)}
              </Box>
              <Box
                sx={{
                  ...borderStyles,
                  ...fieldsStyleGray,
                }}
              >
                {RenderField('Machine Number', contract?.numberOfMachines)}
              </Box>
            </Box>
          </Box>
          <Box sx={contactDetailsBox}>
            <Box sx={contactDetailsInnerBox}>
              <Box sx={fieldsStyle}></Box>
              <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
                {RenderField('Hosting Provider', contract?.machineSupplier)}
              </Box>
              <Box sx={fieldsStyle}>
                {RenderField('Machine TH', contract?.machineTH)}
              </Box>
              <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
                {RenderField('Machine Type', contract?.machineType)}
              </Box>
              <Box sx={fieldsStyle}>
                {RenderField('Machine Watt', contract?.machineWatt)}
              </Box>
              <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
                {RenderField('Miners Cost', contract?.minersCost)}
              </Box>
              <Box sx={fieldsStyle}>
                {RenderField('Hardware Investment', contract?.totalInvestment)}
              </Box>
              <Box
                sx={{
                  ...borderStyles,
                  ...fieldsStyleGray,
                }}
              >
                {RenderField(
                  'Year To Capital Constitution',
                  contract?.yearToCapitalConstitution,
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <InvoicesTable admin={!isCustomer} />
        <Box sx={{ width: '100%' }}>
          <RedesignedTable
            tableName="Machines"
            items={machines}
            columns={columns}
            setShowModal={setMachineAdded}
            handleEdit={handleEdit}
            onRowDelete={(event) => handleDeleteMachine(event)}
            totalPages={totalPages}
            show={isCustomer ? false : true}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={handleCountItems}
            currentPage={currentPage}
            searchable
          />
        </Box>
        {!isCustomer ? (
          <CustomModal
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
          >
            <MachineForm
              setShowModal={setShowEditModal}
              editData={machineData}
              onFinish={fetchMachines}
            />
          </CustomModal>
        ) : null}
        {!isCustomer ? (
          <CustomModal open={delMachine} onClose={() => setDelMachine(false)}>
            <Box className="container">
              <DeleteModal
                title="machine"
                onClick={removeMachine}
                onCancel={() => setDelMachine(false)}
              />
            </Box>
          </CustomModal>
        ) : null}
      </Box>
    </Layout>
  );
};

export default ContractData;
