import { ColumnsType } from 'components/Tables/BaseTable/types';
import { makeUrl } from 'routes/makeUrl';
import { SeeMoreButton } from '../BaseTable/SeeMoreButton';
import { routesMapping } from 'routes/mappings';
import { Box, Typography } from '@mui/material';
import { formatKaspa } from 'shared/utils/formatter';

const containerBold = {
  fontWeight: 600,
};

const alignCenter = {
  width: '100%',
  textAlign: 'center',
};

const suffix = {
  fontWeight: 300,
  color: '#7B7B7B',
  fontSize: '12px',
  display: 'inline',
  marginLeft: '5px',
};

const handleNavigate = (location: any, customerId: any, currency: any) => {
  if (location.pathname.includes('admin/customer-view')) {
    return `${makeUrl(routesMapping.adminViewCustomerOperation, { customerId })}?currency=${currency}`;
  }

  if (location.pathname.includes('affiliate/customer-view')) {
    return `${makeUrl(routesMapping.affiliateViewCustomerOperation, { customerId })}?currency=${currency}`;
  }

  return `${makeUrl(routesMapping.customerOperation, { customerId })}?currency=${currency}`;
};

export const getColumns = (location: any, customerId: any): ColumnsType[] => [
  {
    id: 'coin',
    label: 'Coin',
    minWidth: 150,
    align: 'left',
    sortable: false,
    expandableIcon: true,
    render: (row) => (
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
        <Box>
          <img
            src={row.logo}
            alt={row.coin}
            style={{ width: '40px', height: '40px' }}
          />
        </Box>
        <Box>
          <Typography sx={{ containerBold }}>{row.coinName}</Typography>
          <Typography
            sx={{ fontWeight: 300, color: '#7B7B7B', fontSize: '12px' }}
          >
            {row.coinAbb}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    id: 'totalMachines',
    label: 'Total Miners',
    minWidth: 130,
    align: 'center',
    sortable: false,
    render: ({ totalMachines }) => (
      <Box sx={[containerBold, alignCenter]}>{totalMachines}</Box>
    ),
  },
  {
    id: 'totalHashrate',
    label: 'Total Hashrate',
    sortable: false,
    minWidth: 140,
    align: 'center',
    render: ({ totalHashrate }) => (
      <Box sx={[containerBold, alignCenter]}>
        {(totalHashrate / 1000).toFixed(2)}
        <Typography sx={suffix}>PH/s</Typography>
      </Box>
    ),
  },
  {
    id: 'totalMinedCoins',
    label: 'Past 30 days mined coins',
    minWidth: 120,
    align: 'center',
    sortable: false,
    render: ({ totalMinedCoins, coinAbb }) => (
      <Box sx={[containerBold, alignCenter]}>
        {totalMinedCoins
          ? coinAbb === 'KAS'
            ? formatKaspa(totalMinedCoins)
            : totalMinedCoins.toFixed(6)
          : totalMinedCoins}{' '}
        <Typography sx={suffix}>{coinAbb}</Typography>
      </Box>
    ),
  },
  {
    id: 'details',
    label: 'Details',
    minWidth: 150,
    align: 'left',
    sortable: false,
    render: ({ currency }) => (
      <SeeMoreButton route={handleNavigate(location, customerId, currency)} />
    ),
  },
];
