import { subtitleStyles } from 'components/Cards/styles';
import { useMemo } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { subtitleTextStyle } from '../styles';

const Statistic = (props: any) => {
  const { name, value } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: 1,
        alignItems: 'center',
      }}
    >
      <Typography sx={subtitleTextStyle} fontWeight={500}>
        {name}
      </Typography>
      {value !== null ? (
        <Typography sx={subtitleTextStyle} fontWeight={500} color="secondary">
          {value}
        </Typography>
      ) : (
        <Skeleton variant="rectangular" height={25} width={100} />
      )}
    </Box>
  );
};

export const ChainCardUnavailable = (props: any) => {
  const { type = 'revenue' } = props;

  const chainType = useMemo(
    () => ({
      name: type === 'revenue' ? 'Revenue Synthesis' : 'Chain Difficulty',
      tooltip: type === 'revenue' ? 'Revenue Synthesis' : 'Chain Difficulty',
    }),
    [type],
  );

  return (
    <InfoCard
      info={chainType.tooltip}
      styles={{
        height: '550px',
      }}
      fullHeight
      subtitle={
        <Box>
          <Typography sx={subtitleStyles} variant="h5" fontWeight="500">
            {chainType.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 1,
            }}
          >
            <Typography sx={subtitleTextStyle} color="#000" fontWeight={500}>
              Live
            </Typography>

            <Typography
              sx={subtitleTextStyle}
              color="secondary.main"
              fontWeight={500}
            >
              {'-'}
            </Typography>
          </Box>
        </Box>
      }
      content={
        <Box height="250px">
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography>Data is temporarily unavailable</Typography>
          </Box>
          <Box marginTop={3}>
            <Statistic type={type} name="This Month" value={'-'} />
            <Statistic type={type} name="Past 2 Years" value={'-'} />
          </Box>
        </Box>
      }
    />
  );
};
